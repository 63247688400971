import styled from "styled-components";

export const ArticleBodyDiv = styled.div`
  --leftColumnWidth: 315px;
  width: var(--width);
  max-width: var(--width);
  padding: 0 var(--outerMargin);
  margin: 25px auto 50px auto;
  --eventColor: ${(props) => (props.color ? props.color : "var(--red)")};
  --subTitleColor: ${(props) =>
    props.subTitleColor
      ? props.subTitleColor
      : props.color
      ? props.color
      : "var(--red)"};
  --textColor: ${(props) =>
    props.textColor ? props.textColor : "var(--black)"};
  color: var(--textColor);
  & > h2 {
    color: var(--subTitleColor);
    margin-bottom: 50px;
  }
  & > h5 {
    margin-bottom: 35px;
    & a {
      color: var(--textColor);
    } 
  }
  & > div.alserkal-advisory-container{
    & > div:nth-of-type(2){
      padding:50px 100px 0px;
    }
  }
  & > div {
    display: flex;
    & > div.alserkal-advisory-column-hide{
      display:none;
    }
    & > div:first-of-type {
      width: var(--leftColumnWidth);
      padding-right: 25px;
      z-index:9999999;
      & h5 {
        color: ${(props) => (props.color ? props.color : "var(--red)")};
        margin: 20px 0 10px 0;
      }
      & + div {
        --marginLeft: calc(0px - calc(315px + var(--outerMargin)));
        --marginRight: calc(0px - var(--outerMargin));
      }
    }
  }
  @media (max-width: 1439px) {
    --leftColumnWidth: 180px;
  }
  @media (max-width: 1023px) {
    --outerMargin: 25px;
  }
  @media (max-width: 767px) {
    & > h2 {
      font-size: 24px;
    }
    & > div {
      flex-direction: column;
      & > div:first-of-type {
        margin-bottom: 25px;
      }
    }
  }
`;

export const PhotoEssayWrapper = styled.div`
  background-color: var(--white);
  padding-top: 25px;
  padding-bottom: 50px;
  @media (max-width: 767px) {
    padding-top: 0.1px;
    & > div {
      margin-top: 0;
      & > div > div:first-child {
        display: none;
      }
    }
  }
`;
