import React from "react";
import RichContent from "./../RichContent";
import { H2SmallStrong, H5Small, H5SmallStrong } from "./../Typography";
import { ArticleBodyDiv } from "./elements";
import MiscSocialSmall from "./../icons/MiscSocialSmall";
import { getDate } from "./../../modules/time";

const ArticleBody = ({
  mainColumn,
  leftColumn,
  topline,
  subTitle,
  subTitleColor,
  color,
  textColor,
  date,
  hideSocial,
  className,
  container
}) => {
  // console.log("date");
  // console.log(mainColumn, leftColumn);
  // console.log(hideSocial);
  const hideTheSocial =
    hideSocial ||
    (mainColumn &&
      mainColumn.length &&
      mainColumn[0].typeHandle === "videoWithCaption");
  // console.log(mainColumn);
  return (
    <ArticleBodyDiv
      color={color || "var(--red)"}
      textColor={textColor || "var(--black)"}
      subTitleColor={subTitleColor || "var(--purple)"}
      
    >
      <H5Small>{topline}</H5Small>
      <H2SmallStrong>{subTitle}</H2SmallStrong>
      <div className={container || ""}>
        <div className={className || ""}>
          {leftColumn}
          
          {hideTheSocial ? null : (
            <React.Fragment>
              <H5SmallStrong>Share</H5SmallStrong>
              <MiscSocialSmall color={color || "var(--red)"} />
            </React.Fragment>
          )}
          {date ? <H5SmallStrong>{getDate(date)}</H5SmallStrong> : null}
        </div>
        <RichContent content={mainColumn} accentColor={color} sliderWidth="1240px"/> 
      </div>
    </ArticleBodyDiv>
  );
};
export default ArticleBody;
