import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import FoundationWrapper from "./../../components/FoundationWrapper";
import Selector from "./../../components/Selector";
// import ArticleBody from "./../../components/ArticleBody";
import { /*H3Italic,*/ H3Strong } from "./../../components/Typography";
// import ProgrammeBody from "./../../components/ProgrammeBody";
// import EventGrid from "./../../components/EventGrid";
// import { BigButton } from "./../../components/Buttons";

import {
  ArticleBodyWrapper,
  AboutWrapper,
} from "./../../components/FoundationStyles/elements";
import ArticleBody from "./../../components/ArticleBody";

const CommonRoomPage = () => {
  const pageQuery = useStaticQuery(graphql`
    {
      craftCommonRoomCommonRoomEntry {
        title
        subtitle
        richContent {
          ... on Craft_richContent_matterportEmbed_BlockType {
            typeHandle
            matterportUrl
          }
          ... on Craft_richContent_podiantPlayer_BlockType {
            podiantUrl
            typeHandle
          }
          ... on Craft_richContent_anghamiPlayer_BlockType {
            anghamiUrl
            typeHandle
          }
          ... on Craft_richContent_soundcloudPlayer_BlockType {
            soundcloudUrl
            typeHandle
          }
          ... on Craft_richContent_spotifyPlayer_BlockType {
            spotifyUrl
            typeHandle
          }
          ... on Craft_richContent_image_BlockType {
            id
            typeHandle
            imageWithCaptionImage {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            imageWithCaptionCaption
          }
          ... on Craft_richContent_slideShow_BlockType {
            id
            typeHandle
            images {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            carouselCaption
          }
          ... on Craft_richContent_richText_BlockType {
            id
            typeHandle
            richText
          }
          ... on Craft_richContent_imageSlider_BlockType {
            typeHandle
            imageSliderImages {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            imageSliderCaptions {
              captionLine4
              captionLine3
              captionLine2
              captionLine1
            }
          }
          ... on Craft_richContent_videoWithCaption_BlockType {
            typeHandle
            videoWithCaptionCaption
            videoWithCaptionVideoOnTheLeft
            videoWithCaptionUseCredit
            videoWithCaptionUrl
            videoWithCaptionVideo {
              url
            }
          }
          ... on Craft_richContent_photoWithQuote_BlockType {
            typeHandle
            photoWithQuoteImage {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            photoWithQuoteImageOnTheLeft
            photoWithQuoteImageQuote
            photoWithQuoteImageQuoteAttribution
          }
        }
      }
    }
  `).craftCommonRoomCommonRoomEntry;
  const { title, subtitle, richContent, programmeEventSelector } = pageQuery;
  // console.log(
  //   residencyPageQuery.foundationResidents.map((x) => x.residentImage)
  // );
  // const [shownEvents, setShownEvents] = React.useState([]); // Do we need this?

  // React.useEffect(() => {
  //   const eventList = [];
  //   // console.log("generating event list");
  //   for (let i = 0; i < programmeEventSelector.length; i++) {
  //     const newEvent = programmeEventSelector[i];
  //     newEvent.focusLevel = 0;
  //     eventList[eventList.length] = newEvent;
  //   }
  //   // console.log(eventList);
  //   setShownEvents(eventList);
  // }, []);
  // console.log(shownEvents);

  return (
    <FoundationWrapper title={title} selected selection={3}>
      <AboutWrapper>
        <H3Strong>{subtitle}</H3Strong>
        <ArticleBodyWrapper>
          <ArticleBody
            subTitleColor={"var(--black)"}
            mainColumn={richContent}
            color={"var(--purple)"}
            textColor={"var(--black)"}
          />
        </ArticleBodyWrapper>
      </AboutWrapper>
    </FoundationWrapper>
  );
};

export default CommonRoomPage;
